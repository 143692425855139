import React, { useEffect, useState } from 'react';

import { validTokenHandler, permitHandler, jwtHandler } from '../services/Handlers.js';
import { logout } from '../services/LoginService';

import { Menubar } from 'primereact/menubar';
import { Button } from 'primereact/button';

const menus = jwtHandler() ? jwtHandler().menus : null;

/**
 * 
 *  INICIO MENUS EXISTENTES
 * 
 */

const menuEcoCane = [
    { label: 'Analise Laboratorio', icon: 'fa fa-flask', auth: 'laboratoryAnalysis', url: '/ecocane/analiseLaboratorio/' },
    { label: 'Amostra Laboratorio', icon: 'fa fa-flask', auth: 'laboratoryAnalysis', url: '/ecocane/amostraLaboratorio/' },
    { label: 'Pedidos', icon: 'fa fa-list-ol', auth: 'productionOrders', url: '/ecocane/pedidos/' },
    { label: 'Ordem Produção', icon: 'fa fa-id-badge', auth: 'product', url: '/ecocane/ordemproducao/' },
];

const menuContabilidade = [
    { label: 'Caixa', icon: 'fa fa-money', auth: 'consolidatedCash', url: '/contabilidade/caixa/' },
    { label: 'Ebit e Ebitda', icon: 'fa fa-clipboard', auth: 'ebit', url: '/contabilidade/ebit/consolidado/' },
    { label: 'Datas Impostos', icon: 'fa fa-calendar', auth: 'imposedDates', url: '/contabilidade/datasimpostos/' },
    { label: 'Relatorio Comissao', icon: 'fa fa-pencil-square-o', auth: 'commissionPersonCompanyReport', url: '/contabilidade/relatorioComissaoCadastros/' },
    { label: 'Relatorio Negocio', icon: 'fa fa-briefcase', auth: 'lineBusinessReport', url: '/contabilidade/relatorioNegocio' },
];

const menuLogistica = [
    { label: 'Abastecimentos', icon: 'fa fa-thermometer-half', auth: 'supplies', url: '/logistica/abastecimentos/' },
    { label: 'Agenda CRM', icon: 'fa fa-calendar-o', auth: 'crmSchedule', url: '/logistica/agendacrm/' },
    { label: 'Calculo de Rotas', icon: 'fa fa-calculator', auth: 'routeCalculation', url: '/logistica/calculorota' },
    { label: 'Calculo De Venda', icon: 'fa fa-usd', auth: 'transportSaleCalculation', url: '/logistica/calculovenda/' },
    { label: 'Canhoto de Viagem', icon: 'fa fa-ticket', auth: 'travelStub', url: '/logistica/canhotoNfeCteViagens/' },
    { label: 'Check List Motorista', icon: 'fa fa-check-square', auth: 'driversCheckList', url: '/logistica/checklistmotorista/enviar' },
    { label: 'Compra Venda Biomassa', icon: 'fa fa-exchange', auth: 'buyingSellingBiomass', url: '/logistica/compravendabiomassa/' },
    { label: 'Dashboard', icon: 'fa fa-area-chart', auth: 'dashboard', url: '/logistica/dashboard/' },
    { label: 'Ordem de Embarque', icon: 'fa fa-id-card-o', auth: 'shippingOrder', url: '/logistica/ordemembarque' },
    { label: 'Rotas', icon: 'fa fa-exchange', auth: 'transportSaleCalculation', url: '/logistica/rotas/' },
    { label: 'Viagens Diarias', icon: 'fa fa-suitcase', auth: 'dailyTrips', url: '/logistica/viagensDiarias/' },
];

const menuFinanceiro = [
    { label: 'Fluxo de Caixa Futuros', icon: 'fa fa-line-chart', auth: 'cashFlowForecast', url: '/financeiro/fluxocaixaprevisao/' },
    { label: 'Fluxo de Caixa Realizados', icon: 'fa fa-line-chart', auth: 'cashFlowForecast', url: '/financeiro/fluxocaixaRealizados/' },
];

const menuGeral = [
    { label: 'Compras', icon: 'fa fa-shopping-cart', auth: 'sketchPurchase', url: '/compras/esboco/' },
    { label: 'Aprovações', icon: 'fa fa-thumbs-up', auth: 'sketchPurchase', url: '/compras/aprovacoes/' },
    { label: 'Tempo Documento', icon: 'fa fa-clock-o', auth: 'sketchPurchase', url: '/compras/tempoAprovacao/' },
    { label: 'Estoque', icon: 'fa fa-stack-exchange', auth: 'availableStock', url: '/compras/estoque/' },
    { label: 'Despesa Viagem', icon: 'fa fa-credit-card', auth: 'travelExpense', url: '/geral/despesasDeViagem/' },
    { label: 'Relatorios', icon: 'fa fa-file-text', auth: 'reportGeneral', url: '/geral/relatorios/' },
    { label: 'Usuarios', icon: 'fa fa-user', auth: 'userSystem', url: '/geral/usuarios/' },
    { label: 'Alterar Senha', icon: 'fa fa-key', auth: 'userNewPassword', url: '/geral/alterarsenha/' },
];

const menuFaturamento = [
    { label: 'Emissor Faturas', icon: 'fa fa-share-square', auth: 'invoiceSender', url: '/faturamento/emissorfatura/' },
    { label: 'Ordem Carregamento', icon: 'fa fa-road', auth: 'loadingOrder', url: '/faturamento/cadastraOrdemCarregamento/' },
];

const menuOperacional = [
    { label: 'Resultado Operação Maquinas', icon: 'fa fa-pie-chart', auth: 'costMachines', url: '/operacional/customaquinas' },
    { label: 'Grupos C.C', icon: 'fa fa-align-center', auth: 'groupCostCenter', url: '/operacional/centrocustocrupos' },
    { label: 'Orçamento', icon: 'fa fa-calculator', auth: 'budget', url: '/operacional/orcamento/lista' },
];

const menuAdministrativo = [
    { label: 'Gestão de Documento', icon: 'fa fa-file-text', auth: 'documentManagement', url: '/administrativo/gestaodocumentos' },
    { label: 'Gestão de Pátio', icon: 'fa fa-th-large', auth: 'yardManagement', url: '/administrativo/gestaopatio' },
];

/**
 * 
 *  FIM MENUS EXISTENTES
 * 
 */

export default function MenuBar() {

    const [itensEcoCane, setItensEcoCane] = useState([]);
    const [itensContabilidade, setItensContabilidade] = useState([]);
    const [itensLogistica, setItensLogistica] = useState([]);
    const [itensFinanceiro, setItensFinanceiro] = useState([]);
    const [itensGeral, setItensGeral] = useState([]);
    const [itensFaturamento, setItensFaturamento] = useState([]);
    const [itensOperacional, setItensOperacional] = useState([]);
    const [itensAdministrativo, setItensAdministrativo] = useState([]);

    useEffect(() => {
        
        validTokenHandler();

        corrigeEstiloMenu();

        setItensEcoCane(constructMenu(menuEcoCane));
        setItensContabilidade(constructMenu(menuContabilidade));
        setItensLogistica(constructMenu(menuLogistica));
        setItensFinanceiro(constructMenu(menuFinanceiro));
        setItensGeral(constructMenu(menuGeral));
        setItensFaturamento(constructMenu(menuFaturamento));
        setItensOperacional(constructMenu(menuOperacional));
        setItensAdministrativo(constructMenu(menuAdministrativo));

    }, []); // eslint-disable-line

    function exibeMenuItem(role) {
        return menus && menus.includes(role) ? 'hengelMenuItemEnable' : 'hengelMenuItemDisable';
    };

    let items = [
        { label: 'Contabilidade', icon: 'fa fa-calculator', className: exibeMenuItem('Contabilidade'), items: itensContabilidade},
        { label: 'EcoCane', icon: 'fa fa-leaf', className: exibeMenuItem('Fabrica'), items: itensEcoCane},
        { label: 'Faturamento', icon: 'fa fa-paper-plane', className: exibeMenuItem('Faturamento'), items: itensFaturamento},
        { label: 'Financeiro', icon: 'fa fa-university', className: exibeMenuItem('Financeiro'), items: itensFinanceiro},
        { label: 'Geral', icon: 'fa fa-universal-access', className: exibeMenuItem('Geral'), items: itensGeral},
        { label: 'Operacional', icon: 'fa fa-train', className: exibeMenuItem('Operacional'), items: itensOperacional},
        { label: 'Transporte', icon: 'fa fa-truck', className: exibeMenuItem('Logistica'), items: itensLogistica},
        { label: 'Administrativo', icon: 'fa fa-object-group', className: exibeMenuItem('Administrativo'), items: itensAdministrativo}
    ];

    const constructMenu = (source) => {

        const target = [];

        source.forEach((item) => {

            if (permitHandler(item.auth)) {

                const itemMenu = {
                    label: item.label,
                    icon: item.icon,
                    // className: item.auth && permitHandler(item.auth) ? 'hengelMenuItemEnable' : 'hengelMenuItemDisable',
                    command: () => window.location.href = item.url
                }

                target.push(itemMenu);

            }

        });

        target.sort((a, b) => a.label > b.label ? 1 : b.label > a.label ? -1 : 0);

        return target;

    }

    const corrigeEstiloMenu = () => {
        const menuUl = document.getElementsByClassName("p-menubar-root-list")[0];
        if (menuUl) {
            menuUl.style.flexWrap = 'wrap';
        }
    };

    return (
        <div>

            <Menubar model={items.sort((a, b) => a.label > b.label ? 1 : b.label > a.label ? -1 : 0)}
                end={<Button onClick={() => { logout() }} label="Logout" icon="pi pi-power-off" />}
            />

        </div>
    )

}
